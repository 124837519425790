<template lang="pug">
  v-skeleton(v-if="loading" height='55px')
  .tablist(v-else)
    .ui-tab(
      v-for="(item, index) in tabs"
      :index="index"
      :key="index"
      :class="{ 'active': activeTabIndex === index }"
      @click="onTabClick(index)")
      badge-group(
        v-if="item.mainBadge"
        :color="tabs[1].color")
      badge(
        v-else
        :width="30"
        :level="item.starsCount || index"
        :color="item.color")

</template>

<script>

import Badge from '@/components/svg/Badge.vue'
import BadgeGroup from '@/components/svg/BadgeGroup.vue'

export default {
  name: 'GradationTabs',

  components: {
    Badge,
    BadgeGroup
  },

  props: {
    tabs: [Array],
    activeTabIndex: {
      default: 0,
      type: Number
    },
    loading: Boolean
  },

  data: () => ({
  }),

  methods: {
    onTabClick (index) {
      this.$emit('set-active-tab-index', index)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tablist {
    padding: 0;
    display: flex;

    .ui-tab {
      position: relative;
      padding: 5px;
      padding-bottom: 0;
      cursor: pointer;
      background: $light-blue;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      min-width: 50px;
      min-height: 55px;

      &.active {
        background-color: #FFFFFF;

        &:hover {
          .icon-close {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
</style>
