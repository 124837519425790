<template lang="pug">
  .insights__detail-drawer
    .insights__detail-drawer__header
      h2.title {{ title }}
    .insights__detail-drawer__body
        .insight-detail__general-statistics(:class="{'insight-detail__general-statistics--goals' : isGoals}")
          .insight-detail__general-statistics--items(
            v-for="item in numbersStatistics"
            :key="item.title")
            p.insight-detail__general-statistics--items--value {{ item.value }}
            p {{ item.title }}
        v-scrollable(updatable)
          details-pie-chart(
            :pieChartData="pieChartData")

</template>

<script>
import DetailsPieChart from '../common/DetailsPieChart'

export default {
  components: {
    DetailsPieChart
  },

  props: {
    title: String,
    numbersStatistics: Array,
    isGoals: Boolean,
    pieChartData: Array
  }
}
</script>

<style lang="scss" scoped>

  .insight-detail {
    &__general-statistics {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 2rem;

      &--items {
        max-width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 0.5rem;
        font-weight: bold;
        font-size: 0.9rem;

        p {
          margin-bottom: 9px;
        }

        &--value {
          font-size: 1.6rem;
          color: $color-primary;
        }

        &:nth-child(2){
          .insight-detail__general-statistics--items--value {
            color: $color-grey
          }
        }
      }
    }
  }

  .insight-detail__general-statistics--goals {
    .insight-detail__general-statistics--items{
        &:nth-child(odd){
          .insight-detail__general-statistics--items--value {
            color: $color-orange !important
          }
        }
    }
  }
</style>
