<template lang="pug">
  .insights__detail-drawer
    .insights__detail-drawer__header
        v-skeleton(style="margin-bottom:5px" height="27px")
        v-skeleton(height="24px")
    .insights__detail-drawer__body
      v-skeleton(style="margin-bottom:15px" height="30px")
      v-skeleton(style="margin-bottom:10px" height="30px")
      v-skeleton(height="30px")
</template>
<script>
export default {
  name: 'DrawerBadgeDataLoader'

}
</script>

<style lang="scss">
</style>
