<template lang="pug">
  .insights-graphics
    span.insights-graphics__ticks-title {{'pages.insights.number_of_employees' | translate}}
    .insights-graphics__horizontal-ticks(ref="horizontalTics")
      span.insights-graphics__horizontal-ticks--element(v-for="(tick, index) in tickSteps" :key="index") {{ tick }}
    .insights-graphics--scroll-wrapper(ref="chartjsScrollWrapper")
      vue-scroll(:ops="scrollOps" @handle-scroll="onScroll")
        .insights-graphics--container(:style="chartScrollStyle" ref="chartJsContainer")
          v-bar-chart(:chartdata="chartData" :options="options" ref="chart")
          .tooltip-chart-js(id="chartJsTooltip" ref="chartJsTooltip")
          span.insights-graphics--container__label-tooltips(
            v-for="(item, index) in chartData.labels"
            v-if="13 < item.length"
            :style="labelTooltipsStyle(index)"
            v-tooltip="{ content: item,  placement: 'right-start', trigger: 'manual', show: index === prevHoveredTooltipIndex }")
</template>

<script>

let xAxesTicksHeight = 75
let barThicknessWidth = 40
let barSpacing = 12
let substringMax = 13
export default {
  name: 'Graphics',

  props: {
    chartData: Object,
    selectedBar: Object,
    maxChartValue: Number
  },

  mounted () {
    this.setMaxValue(
      this.getRoundedMaxValue(this.maxChartValue))
  },

  destroyed () {
    this.resetData()
  },

  data: (vm) => ({
    scrollOps: {
      scrollPanel: {
        scrollingY: false,
        scrollingX: true
      },
      rail: {
        disable: false,
        background: '#000',
        opacity: 0.1
      },
      bar: {
        disable: false,
        keepShow: true,
        opacity: 1
      }
    },

    isEnabledIntersect: true,
    currentTooltipOpened: null,
    clonedTolltipsIds: [],
    savedTooltipsIds: [],
    lastHoveredTooltip: null,
    prevHoveredTooltipIndex: null
  }),

  methods: {
    update () {
      this.$refs.chart && this.$refs.chart.$data._chart.update()
    },

    resetData () {
      let array = [...this.clonedTolltipsIds, ...this.savedTooltipsIds]
      for (let i in array) {
        let element = document.getElementById(array[i])
        if (element) {
          element.style.display = 'none'
          element.parentNode.removeChild(element)
        }
      }
      this.clonedTolltipsIds = []
      this.savedTooltipsIds = []
      this.prevHoveredTooltipIndex = null
      this.lastHoveredTooltip = null
    },

    onScroll (vertical, horizontal, nativeEvent) {
      const scrollWidth = nativeEvent.target.scrollWidth - nativeEvent.target.clientWidth
      const scrollLeft = horizontal.scrollLeft
      if (scrollLeft >= scrollWidth) {
        this.$emit('scroll-complete')
      }
    },

    chartClick (e, data, index = null) {
      let selectedData = null
      if ((data && data.length) || index !== null) {
        selectedData = {
          index: index !== null ? index : data[0]._index,
          subIndex: null
        }
        if (!this.currentTooltipOpened || (this.currentTooltipOpened && this.lastHoveredTooltip && (this.currentTooltipOpened.dataPoints[0].index !== data[0]._index) &&
        this.currentTooltipOpened.dataPoints[0].index !== this.lastHoveredTooltip.dataPoints[0].index)) {
          this.currentTooltipOpened = Object.assign(this.lastHoveredTooltip, {})
        }
        this.setHoverMode('nearest')
      } else {
        this.currentTooltipOpened = null
        this.setHoverMode('x')
      }

      if (selectedData && this.selectedBar && this.selectedBar.index === selectedData.index) {
        let getDataAtEvent = this.$refs.chart.$data._chart.getElementAtEvent(e)
        selectedData.subIndex = getDataAtEvent.length
          ? getDataAtEvent[0]._datasetIndex : null
      }

      this.$emit('on-select', selectedData)
    },

    onHoverChart (e, chartElement) {
      if (chartElement[0] && this.currentTooltipOpened && this.$refs.chart.$data._chart.options.hover.mode === 'x' &&
            chartElement[0]._index === this.currentTooltipOpened.dataPoints[0].index) {
        this.setHoverMode('nearest')
      } else if (chartElement[0] && this.currentTooltipOpened && this.$refs.chart.$data._chart.options.hover.mode === 'nearest' &&
            chartElement[0]._index !== this.currentTooltipOpened.dataPoints[0].index) {
        this.setHoverMode('x')
      }
      const container = this.$refs.chartJsContainer
      let isIntesectEnable = e.layerY < (Math.round(container.getBoundingClientRect().height) - xAxesTicksHeight)
      e.target.style.cursor = chartElement[0] || this.prevHoveredTooltipIndex !== null ? 'pointer' : 'default'

      if (isIntesectEnable !== this.isEnabledIntersect) {
        this.toggleIntersect(isIntesectEnable)
      }
    },

    toggleIntersect (param) {
      this.isEnabledIntersect = param
      this.$refs.chart.$data._chart.options.tooltips.intersect = param
      this.update()
    },

    setMaxValue (param) {
      this.$refs.chart.$data._chart.options.scales.yAxes[0].ticks.max = param
      this.update()
    },

    setHoverMode (mode = 'x') {
      if (this.$refs.chart.$data._chart.options.hover.mode !== mode) {
        this.$refs.chart.$data._chart.options.hover.mode = mode
        this.update()
      }
    },

    setVerticalTickHeight (height) {
      xAxesTicksHeight = Math.floor(height)
      this.$refs.horizontalTics.style.height = this.$refs.chart.$refs.canvas.getBoundingClientRect().height - xAxesTicksHeight + 'px'
    },

    onCompleteChart () {
      const onComplete = () => {
        const container = this.$refs.chartJsContainer
        container.onclick = (e) => {
          let isLabelClicked = e.layerY > (Math.round(container.getBoundingClientRect().height) - xAxesTicksHeight)
          if (isLabelClicked) {
            this.chartClick(e, null, this.prevHoveredTooltipIndex)
          }
        }
      }
      setTimeout(() => onComplete(), 0)
    },

    customTooltip (tooltipModel, isSavedMode = false) {
      // prevend add mouse over toolltip, when has saved
      if (tooltipModel && tooltipModel.opacity !== 0 && !isSavedMode && this.isEnabledIntersect &&
        this.currentTooltipOpened && this.currentTooltipOpened.dataPoints[0].index === tooltipModel.dataPoints[0].index) {
        return null
      }

      if (!isSavedMode && tooltipModel && this.currentTooltipOpened) {
        for (let i in this.savedTooltipsIds) {
          let clonedTooltip = document.getElementById(this.savedTooltipsIds[i])
          if (tooltipModel.dataPoints &&
                Math.abs(this.currentTooltipOpened.dataPoints[0].index - tooltipModel.dataPoints[0].index) < 3) {
            clonedTooltip.classList.add('saved_tooltip-chart-js__opacitied')
          } else {
            clonedTooltip.classList.remove('saved_tooltip-chart-js__opacitied')
          }
        }
      }

      if (!isSavedMode) {
        for (let i in this.clonedTolltipsIds) {
        // remove opacity and  add display none
          let clonedTooltip = document.getElementById(this.clonedTolltipsIds[i])
          clonedTooltip.style.display = 'none'
          if (tooltipModel.dataPoints && this.prevHoveredTooltipIndex !== tooltipModel.dataPoints[0].index) {
            clonedTooltip.classList.remove('opacity-fade')
          }
        }

        if (tooltipModel.opacity === 0) this.prevHoveredTooltipIndex = null
      } else {
        for (let i in this.savedTooltipsIds) {
          // remove opacity and  add display none
          let savedTooltip = document.getElementById(this.savedTooltipsIds[i])
          savedTooltip.style.display = 'none'
        }
      }

      if (tooltipModel && tooltipModel.opacity !== 0) {
        if (!isSavedMode) {
          this.prevHoveredTooltipIndex = tooltipModel.dataPoints[0].index
        }
        this.lastHoveredTooltip = tooltipModel
        const initialTooltipid = 'chartJsTooltip'
        const tooltipElModel = this.$refs[initialTooltipid]
        const container = this.$refs.chartJsContainer
        const scrollWrapper = this.$refs.chartjsScrollWrapper
        let bottomExtremePoint = Math.round(container.getBoundingClientRect().height) - xAxesTicksHeight
        let copiedBottomExtremePoint = bottomExtremePoint
        const groupedArray = []
        let copiedGroupedArray = JSON.parse(JSON.stringify(groupedArray))
        const standartGroup = {
          groups: []
        }

        let titleLines = tooltipModel.title || []
        let bodyLines = (tooltipModel.body || []).map((bodyItem) => {
          return bodyItem.lines
        })

        // group too short graphics
        let group = Object.assign(standartGroup, {})

        for (let i in tooltipModel.dataPoints) {
          if (!titleLines[i] || !bodyLines[i]) continue
          let topPosotion = window.pageYOffset + tooltipModel.dataPoints[i].y
          let tooltipBottomExtremePoitn = topPosotion + 55

          const data = { ...tooltipModel.dataPoints[i],
            title: titleLines[i],
            body: bodyLines[i] }

          const pushedData = (data) => {
            groupedArray.push(data)
            group = Object.assign(standartGroup, {})
          }

          // check bottom postions for all elements and create group if needed
          const addPrevDataToGroup = (index, putdownCount) => {
            if (index >= 0) {
              group.groups.push(...groupedArray.splice(index, 1))
              if (copiedGroupedArray[index - 1] && copiedGroupedArray[index].y + putdownCount > copiedGroupedArray[index - 1].y) {
                addPrevDataToGroup(index - 1, putdownCount)
              }
            }
          }

          if (tooltipBottomExtremePoitn > copiedBottomExtremePoint) {
            copiedBottomExtremePoint = copiedBottomExtremePoint - 55
            let putDownPrevData = 0

            if (copiedBottomExtremePoint < 0) {
              putDownPrevData = 0 - copiedBottomExtremePoint
              copiedGroupedArray = JSON.parse(JSON.stringify(groupedArray))
              addPrevDataToGroup(groupedArray.length - 1, putDownPrevData)
              copiedBottomExtremePoint = 0
            }
            group.y = copiedBottomExtremePoint
            group.groups.unshift({ ...data,
              y: copiedBottomExtremePoint
            })
          } else {
            if (group.groups.length === 1) {
              pushedData(group.groups[0])
            } else if (group.groups.length > 1) {
              pushedData(group)
            }
            groupedArray.push({ ...data,
              y: topPosotion
            })
            copiedBottomExtremePoint = topPosotion
          }

          // push groups if other data is empty
          if (+i === tooltipModel.dataPoints.length - 1 && group.groups.length) {
            if (group.groups.length === 1) {
              pushedData(group.groups[0])
            } else if (group.groups.length > 1) {
              pushedData(group)
            }
          }
        }

        // render
        for (let i in groupedArray) {
          const generatedId = (isSavedMode ? 'saved_' : '') + `${initialTooltipid}_${i}`
          let clonedTooltip = document.getElementById(generatedId)
          let item = groupedArray[i]
          if (!clonedTooltip) {
            clonedTooltip = tooltipElModel.cloneNode(true)
            if (isSavedMode) {
              clonedTooltip.classList.add('saved_tooltip-chart-js')
              this.savedTooltipsIds.push(generatedId)
            } else {
              this.clonedTolltipsIds.push(generatedId)
            }
            clonedTooltip.id = generatedId
            container.appendChild(clonedTooltip)
          }

          const innerTooptipBody = (title, body) => {
            return `<div class="tooltip-chart-js--container"><span class="tooltip-chart-js--title"> ${title || ''} </span>` +
                  `<span class="tooltip-chart-js--body"> ${body || ''} </span></div>`
          }

          const renderContent = (item) => {
            clonedTooltip.style.display = 'inline'
            clonedTooltip.style.left = window.pageXOffset + Math.floor(tooltipModel.caretX + 2) + 'px'
            clonedTooltip.style.top = item.y + 'px'
            setTimeout(() => {
              const containerRightExtreme = scrollWrapper.getBoundingClientRect().left + scrollWrapper.getBoundingClientRect().width
              const tooltipRightExtreme = clonedTooltip.getBoundingClientRect().left + clonedTooltip.offsetWidth
              if (tooltipRightExtreme > containerRightExtreme) {
                clonedTooltip.style.left = -barThicknessWidth - clonedTooltip.offsetWidth + window.pageXOffset + Math.ceil(tooltipModel.caretX) + 2 + 'px'
              }
            }, 0)
          }

          if (item.groups) {
            let innerHtml = ''
            for (let groupI in item.groups) {
              innerHtml += innerTooptipBody(item.groups[groupI].title, item.groups[groupI].body)
            }
            renderContent(item)
            clonedTooltip.innerHTML = innerHtml
          } else {
            renderContent(item)
            clonedTooltip.innerHTML = innerTooptipBody(item.title, item.body)
          }

          // add opacity transition
          if (isSavedMode) {
            clonedTooltip.classList.add('opacity-fade')
          } else {
            setTimeout(() => {
              clonedTooltip.classList.add('opacity-fade')
            }, 0)
          }
        }
      }
    },

    getRoundedMaxValue (value) {
      let defaultStep = 50
      if (value <= 5) {
        defaultStep = 5
      } else if (value <= 10) {
        defaultStep = 10
      } else if (value <= 25) {
        defaultStep = 25
      }

      const rest = value % defaultStep
      return rest
        ? value + defaultStep - rest
        : value
    },

    labelTooltipsStyle (index) {
      let left = ((index + 1) * barThicknessWidth) + ((index + 1) * (barSpacing - index / 100))
      return `left: ${left - 12}px; bottom: ${xAxesTicksHeight - 20}px`
    }
  },

  computed: {
    options () {
      return {
        responsive: true,
        onClick: (e, data) => {
          this.chartClick(e, data)
        },
        animation: {
          onComplete: this.onCompleteChart(),
          duration: 0
        },
        onHover: (e, chartElement) => {
          this.onHoverChart(e, chartElement)
        },
        hover: {
          mode: 'x',
          animationDuration: 0
        },

        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
          mode: 'index',
          intersect: true,
          custom: (tooltipModel) => {
            this.customTooltip(tooltipModel)
          },
          animation: {
            duration: 0
          },
          showLines: false,
          responsiveAnimationDuration: 0,
          displayColors: false,
          callbacks: {
            label: (tooltipItem, data) => {
              let tooltip = data['datasets'][tooltipItem.datasetIndex].tooltipLabels[tooltipItem.index]
              if (tooltip) {
                return tooltip.count + ' ' + this.$t('ui.labels.users')
              }
              return null
            },

            title: (tooltipItem, data) => {
              const tooltips = []
              for (let i in tooltipItem) {
                let tooltip = data['datasets'][tooltipItem[i].datasetIndex].tooltipLabels[tooltipItem[i].index]
                tooltips.push(tooltip ? tooltip.label : null)
              }
              if (tooltips.length) {
                return tooltips
              }
              return null
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            stacked: true,
            ticks: {
              display: false,
              max: this.maxChartValue
            },
            gridLines: {
              display: false
            }
          }],
          xAxes: [ {
            stacked: true,
            gridLines: {
              display: false
            },
            afterFit: (scale) => {
              this.setVerticalTickHeight(scale.height || 85)
            },

            ticks: {
              callback: function (str) {
                return str.length > substringMax ? `${str.substring(0, substringMax)}...` : str
              },
              fontSize: 12,
              fontColor: '#000',
              minRotation: 90
            },
            barThickness: barThicknessWidth
          }]
        }
      }
    },

    chartScrollStyle () {
      if (this.chartData && this.chartData.labels) return `width: ${((this.chartData.labels.length * barThicknessWidth) + (this.chartData.labels.length * barSpacing))}px;`
      else return ''
    },

    tickSteps () {
      const maxValue = this.getRoundedMaxValue(this.maxChartValue)
      const interval = Math.round(maxValue / 5)
      return [0, interval * 1, interval * 2, interval * 3, interval * 4, maxValue]
    }
  },

  watch: {
    currentTooltipOpened (value) {
      this.customTooltip(value, true)
    },

    maxChartValue (value) {
      this.setMaxValue(
        this.getRoundedMaxValue(value))
    }
  }
}
</script>

<style lang="scss">

.insights-graphics {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1240px;
  height: calc(62vh + 136px);
  padding-top: 2rem;

  &--scroll-wrapper {
    width: calc(100% - 140px);
    position: relative;
  }

  &__ticks-title {
    width: 85px;
    color: $color-black;
    height: fit-content;
    font-weight:600;
    font-size: 13px;
    transform: translate(0, -1rem);
  }

  &__horizontal-ticks {
    margin-right: 10px;
    width:45px;
    border-right: 1px solid #707070;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 5px;
    justify-content: space-between;
    align-items: center;

    &--element {
      line-height: 1rem;
      height: 1rem;
      width: 35px;
      text-align: end;
      position: relative;
      padding-right: 6px;
      color: #000000;

      &:after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: #707070;
        width: 11px;
        margin-left: 5px;
        top: 50%;
      }
    }

    &--element:nth-child(1){
      transform: translate(0%, 50%);
      &:after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: #707070;
        width: 11px;
        margin-left: 5px;
        top: 50%;
      }
    }

     &--element:last-child {
      transform: translate(0%, -50%);
      &:after {
        position: absolute;
        content: "";
        height: 1px;
        background-color: grey;
        width: 11px;
        margin-left: 5px;
        top: 50%;
      }
    }

  }

  #bar-chart {
    width: 100% !important;
    height: calc(60vh + 100px) !important;
  }

  .opacity-fade {
    opacity: 1 !important;
  }

  .saved_tooltip-chart-js{
    z-index:1 !important;
  }

  .saved_tooltip-chart-js__opacitied{
    opacity: 0 !important;
    z-index: -100 !important;
  }

  .tooltip-chart-js {
    display: none;
    background: #353535 0% 0% no-repeat padding-box;
    z-index: 2;
    color:white;
    opacity: 0;
    position: absolute;
    transform: translate(18px, 0%);
    pointer-events:'none';
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;

    &--container{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      height: 55px;
      padding: 0.5rem;
    }

    &--title{
      letter-spacing: 0px;
      color:white;
      font-size: 1rem;
      margin-bottom:2px
    }

    &--body {
      letter-spacing: 0px;
      text-overflow: ellipsis;
      display: block;
      color:white;
      font-size: 0.9rem;
    }

  }

  &--container {
    position: relative;

    &__label-tooltips{
      position: absolute;
    }
  }
}
</style>
