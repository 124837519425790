<template lang="pug" functional>
  v-expansion-checkbox(:item="props.item")
    template(slot='body' v-if="props.item.children && props.item.children.length")
      v-expansion-checkbox-list(v-for="(childItem, index) in props.item.children" :item="childItem" :key="props.item.id + props.item.name + index")
</template>

<script>

export default {
  name: 'VExpansionCheckboxList',

  props: {
    item: Object
  }
}
</script>

<style lang="scss" scoped>
</style>
