<template lang="pug" functional>
   svg(
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.49 50.3"
    style="enable-background:new 0 0 41.49 50.3;"
    xml:space="preserve"
    :width="props.width"
    :height="props.height")
    g(transform="translate(1.25 1.271)")
      g(transform="translate(1.25 1.271)")
      path.st0(
        :stroke="props.color"
        d="M24.11,37.51c-2.54-2.02-5.2-3.9-7.96-5.61c-1.84-1.1-3.32-2.7-4.26-4.63c-0.94-1.84-1.44-3.86-1.47-5.92V2.6L24.69,0l14.3,2.6v18.75c-0.03,2.06-0.53,4.09-1.47,5.92c-0.94,1.92-2.42,3.53-4.26,4.63C27.84,35.1,26.86,35.67,24.11,37.51z")
      path.st1(
        :stroke="props.color"
        d="M19.75,42.44c-2.63-1.87-4.62-3.32-8.5-5.61c-1.84-1.09-3.32-2.7-4.26-4.63c-0.94-1.84-1.44-3.86-1.47-5.92V7.53l14.27-2.6l14.3,2.6v18.75c-0.03,2.06-0.54,4.09-1.47,5.92c-0.94,1.92-2.42,3.53-4.26,4.63C22.94,40.03,22.5,40.6,19.75,42.44z")
      path.st2(
        :stroke="props.color"
        :fill="props.color"
        d="M14.23,47.51c-2.74-2.01-5.57-3.88-8.5-5.61c-1.84-1.1-3.32-2.71-4.26-4.63C0.53,35.43,0.03,33.4,0,31.34V12.59l14.27-2.6l14.3,2.6v18.75c-0.03,2.06-0.53,4.09-1.47,5.92c-0.94,1.92-2.42,3.53-4.26,4.63C17.43,45.1,16.98,45.67,14.23,47.51z"
       )

</template>

<script>
import { COLOR_PRIMARY } from '@/util/constants'
export default {
  props: {
    width: {
      type: [String, Number],
      default: 25
    },
    height: {
      type: [String, Number],
      default: 45
    },
    color: {
      type: String,
      default: COLOR_PRIMARY
    }
  }
}
</script>

<style lang="scss" scoped>

.st0{
  fill:none;
  stroke-width:2.5;
}

.st1{
  fill-rule:evenodd;
  clip-rule:evenodd;
  fill:#FFFFFF;
  stroke-width:2.5;
}

.st2{
  fill-rule:evenodd;
  clip-rule:evenodd;
  stroke-width:2.5;
}
</style>
