<template lang="pug">
  v-drawer(
    side="right"
    :width="500"
    :minimize="!showDetailDrawer"
    @on-toggle="setDetailDrawerState"
    :title="modalTitle")
    gradational-tabs(
        slot="tabs"
        v-if="selectedBar"
        :tabs="selectedBadges"
        :loading="!isLoadedBagde"
        :activeTabIndex="activeTabIndex"
        @set-active-tab-index="setActiveTabIndex")
    template(v-if="selectedBar && isLoadedBagde")
      drawer-badge-data(
        v-for="(badge, index) in selectedBadges"
        :key="index"
        v-show="activeTabIndex === index"
        :title="badge.displayName"
        :activeBadge="badge"
        :subtitle="subTitle"
        :pieChartData="badge.pieChartData"
        :getEmployees="getEmployeesByBadgeId")
    drawer-badge-data-loader(
      v-else-if="selectedBar && !isLoadedBagde")
    drawer-general-data(
      v-else-if="generalStats"
      :title="modalTitle"
      :numbersStatistics="numbersStatistics"
      :pieChartData="generalStats.pieChartData")
    v-skeleton(v-else height="300px")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DrawerGeneralData from '../../common/DrawerGeneralData'
import DrawerBadgeData from '../../common/DrawerBadgeData'
import GradationalTabs from '../../common/GradationalTabs'
import DrawerBadgeDataLoader from '../../common/DrawerBadgeDataLoader'
export default {
  name: 'DetailDrawer',

  components: {
    DrawerGeneralData,
    DrawerBadgeDataLoader,
    DrawerBadgeData,
    GradationalTabs
  },

  data: () => ({
    showDetailDrawer: false,
    activeTabIndex: 0
  }),

  methods: {
    ...mapActions('insightsAllSkills', ['selectBar', 'getEmployeesByBadgeId']),

    setDetailDrawerState () {
      this.showDetailDrawer = !this.showDetailDrawer
    },

    setActiveTabIndex (index) {
      let subTab = this.activeTab.currentTab
      this.selectedBadges[index].currentTab = subTab
      this.activeTabIndex = index
      this.selectBar({
        index: this.selectedBar.index,
        subIndex: index === 0 ? null : index - 1,
        isFromDrawerPanel: true
      })
    }
  },

  computed: {
    ...mapGetters('insightsAllSkills', [
      'generalStats',
      'selectedBar',
      'badges',
      'barChartData',
      'companyInfo'
    ]),

    numbersStatistics () {
      return [
        { value: +this.generalStats.activeSkillCount, title: this.$t('pages.insights.active_skills') },
        { value: +this.generalStats.recordedSkillCount, title: this.$t('pages.insights.recorded_skills') },
        { value: +this.generalStats.averageSkillCount.toFixed(1), title: this.$t('pages.insights.avg_skills_p_user') }
      ]
    },

    selectedBadges () {
      return this.selectedBar && this.isLoadedBagde && this.badges[this.selectedBar.index] ? this.badges[this.selectedBar.index] : []
    },

    isLoadedBagde () {
      return this.selectedBar && this.badges[this.selectedBar.index] && this.badges[this.selectedBar.index][0].isLoaded
    },

    activeTab () {
      return this.selectedBadges[this.activeTabIndex] || {}
    },

    subTitle () {
      if (this.selectedBadges[0]) {
        let names = []
        let getParentName = (data) => {
          if (data.parent) {
            names.unshift(data.parent.name)
            getParentName(data.parent)
          }
        }
        getParentName(this.selectedBadges[0])
        return names.join('/')
      }
      return ''
    },

    modalTitle () {
      if (this.selectedBar) {
        return this.barChartData.labels[this.selectedBar.index]
      }
      return this.companyInfo ? this.companyInfo.companyName : ''
    }
  },

  watch: {
    selectedBar: {
      handler (value) {
        if (value && value.subIndex !== null) {
          this.activeTabIndex = value.subIndex + 1
        } else {
          this.activeTabIndex = 0
        }
        if (value && !this.showDetailDrawer) {
          this.showDetailDrawer = true
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.test-sl {
  height: 55px;
  background-color: red;
  width:100px
}
</style>
