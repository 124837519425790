<template lang="pug">
  v-dialog(
    :width="900"
    :value="show"
    @input="onClose")
    v-card
      v-card-head
        v-card-head-label
          h3.title {{ 'pages.insights.filter_settings' | translate }}
          p.subtitle {{ 'infotext.select_departments_locations_and_message' | translate }}
        v-card-head-toolbar
          button.close(type="button" @click="onClose" style="min-width: 0px; height:25px")
            span(aria-hidden="true") ×
      v-card-content
          .filter-modal__body
            .filter-modal__body--table
              .filter-modal__body--header(v-for="col in tableCols" :key="col.key")
                  span {{col.name}}
            v-scrollable(
              updateble
              :minHeight="0"
              :bottom-offset="120")
              .filter-modal__body--table
                .filter-modal__body--content(v-for="col in tableCols" :key="col.key")
                  .filter-modal__body--content--cols
                    v-expansion-checkbox-list(v-for="(item, index) in col.data" :key="item.name + index" :item="item")
      v-card-actions
        span.filter-modal--filter-info {{ 'pages.insights.filtering_for' | translate }}
          b  {{ selectedDepartmentsLength }}/{{ departmentsLength }} {{ 'ui.labels._departments' | translate }}
          b  {{ selectedLocationsLength }}/{{ locationsLength }} {{ 'ui.labels._locations' | translate }}
          |  {{ 'ui.labels._and' | translate }}
          b  {{ selectedSkillsLength }}/{{ skillsLength }} {{ 'ui.labels._skills' | translate }}
        span.active-dark-text.insight-all-skills--reset(@click="resetAll") {{ 'ui.buttons.reset_filter' | translate }}
        v-btn(
          :loading="loading"
          @click="onApply") {{ 'ui.buttons.filter' | translate }}

</template>

<script>
import VExpansionCheckboxList from '../common/VExpansionCheckboxList'

export default {
  name: 'FilterModal',

  components: {
    VExpansionCheckboxList
  },

  data: (vm) => ({
    loading: false
  }),

  props: {
    show: Boolean,
    locations: Array,
    departments: Array,
    skills: Array
  },

  methods: {
    onClose () {
      this.$emit('on-close')
    },

    onApply () {
      this.$emit('on-apply')
    },

    resetAll () {
      this.$emit('on-reset')
    },

    getSelectedFilterLength (data) {
      let count = 0
      const onFilter = (array) => {
        for (let i in array) {
          if (array[i].checked) {
            count += 1
          }
          onFilter(array[i].children || [])
        }
      }
      onFilter(data)
      return count
    },

    getFilterLength (data) {
      let count = 0
      const onFilter = (array) => {
        for (let i in array) {
          count += 1
          onFilter(array[i].children || [])
        }
      }
      onFilter(data)
      return count
    },

    onSortData (array) {
      let sorted = array.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
      for (let i in sorted) {
        if (sorted[i].children) { sorted[i].children = this.onSortData(sorted[i].children) }
      }
      return sorted
    }
  },

  computed: {
    tableCols () {
      return [
        { name: this.$t('ui.labels.departments'), key: 'departments', data: this.onSortData(this.departments) },
        { name: this.$t('ui.labels.locations'), key: 'locations', data: this.onSortData(this.locations) },
        { name: this.$t('ui.labels.skills'), key: 'skills', data: this.skills }
      ]
    },

    selectedDepartmentsLength () {
      return this.getSelectedFilterLength(this.departments)
    },

    selectedSkillsLength () {
      return this.getSelectedFilterLength(this.skills)
    },

    selectedLocationsLength () {
      return this.getSelectedFilterLength(this.locations)
    },

    departmentsLength () {
      return this.getFilterLength(this.departments)
    },

    skillsLength () {
      return this.getFilterLength(this.skills)
    },

    locationsLength () {
      return this.getFilterLength(this.locations)
    }

  }
}
</script>

<style lang="scss" scoped>

.filter-modal {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--table {
      width:100%;
      display: flex;
    }

    &--header {
      border-bottom: 1px solid grey;
      padding: .5rem;
      width: 100%;

      span {
        width: 100%;
        color: #333333;
        margin-right:  3%;
        font-weight: 600;
      }

    }

    &--content {
      width: 100%;
      padding: 1rem .5rem;
      &--cols {
        width: 100%;
        margin-right:  3%;
      }
    }
  }

  &--filter-info {
    font-size: 15px;
    margin-right: 50px;
  }
}
</style>
